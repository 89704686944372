.Organization-Wrapper {
  display: flex;

  .Organization-Configures {
    margin-right: 24px;

    h3 {
      font-weight: 700;
    }

    .Organization-Info {
      background-color: #f2f3f4;
      border-radius: 2px;
      font-size: 16px;
      line-height: 2.4;
      margin-bottom: 32px;
      padding: 8px 16px;

      .Organization-Info-Row {
        > span {
          display: inline-block;
          width: 120px;
        }
      }
    }

    .Organization-Setting:not(:last-child) {
      margin-bottom: 16px;
    }

    .Organization-Setting-Row {
      align-items: center;
      display: flex;
      line-height: 2.4;

      > span {
        display: inline-block;
        font-size: 15px;
        width: 150px;
      }

      > input {
        margin-right: 4px;
        width: unset;
      }
    }
  }
}
