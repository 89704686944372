.Logo-Brand {
  background-color: rgba($color: #fff, $alpha: 0.3);
  float: left;
  height: 32px;
  margin: 16px 24px 16px 0;
  width: 200px;
}

.Layout-Aside {
  border-right: 1px solid #d5d8db;
}

.Layout-Content {
  background-color: #fff;
  margin: 16px;
  padding: 16px 24px;
}

.Content-Standalone {
  margin: 8px;
  padding: 0;
}

.Layout-Footer {
  border-top: 1px solid #d5d8db;
  text-align: center;
}

.Header-Credential {
  a {
    padding: 4px;
  }

  a:not(:last-child) {
    margin-right: 12px;
  }
}
